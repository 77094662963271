import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import CookieConsent from 'react-cookie-consent'
import * as Sentry from '@sentry/react'
import routes from './routes'
import Home from './screens/Home'
import Form from './screens/Form'
import Uploads from './screens/uploads'
import NotAuthorized from './components/NotAuthorized'
import NotFound from './components/NotFound'
import Thanks from './screens/thanks'
import Maintenance from './components/Maintenance'
import './App.css'
import useJiraScript from './hooks'
import useMaintenance from './hooks/useMaintenance'

if (process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENV,
    dsn: process.env.REACT_APP_SENTRY_DNS,
  })
}

const App: React.FC = () => {
  useJiraScript()
  const { isInMaintenance, verifyMaintenance } = useMaintenance()

  console.log({ isInMaintenance })

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <BrowserRouter>
        <header>
          <div className="wrap">
            <a id="logo" href="/" style={{ textIndent: -10000 }}>
              Logo
            </a>
            <div id="burger"></div>
            <nav id="main_menu">
              <a href="https://particuliers.howdens-cuisines.com">Site Grand Public</a>
              <a href="https://pro.howdens-cuisines.com" className="actif">
                Espace Pro
              </a>
            </nav>
          </div>
        </header>
        <main>
          <Switch>
            <Route exact path={routes.HOME}>
              <Home />
            </Route>
            <Route exact path={routes.FORM}>
              {isInMaintenance && verifyMaintenance ? <Maintenance {...verifyMaintenance} /> : <Form />}
            </Route>
            <Route exact path={routes.UPLOAD}>
              <Uploads />
            </Route>
            <Route exact path={routes.NOT_AUTORIZED}>
              <NotAuthorized />
            </Route>
            <Route exact path={routes.THANKS}>
              <Thanks />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </main>
        <footer>
          <div className="wrap">
            <nav>
              <p>Réseaux sociaux</p>
              <a href="https://www.facebook.com/howdenscuisines" rel="noopener" target="_blank">
                Facebook
              </a>
              <a href="https://www.instagram.com/howdenscuisines" rel="noopener" target="_blank">
                Instagram
              </a>
            </nav>
            <nav>
              <p>Informations légales</p>
              <a href="https://www.howdens-cuisines.com/conditions-generales-utilisation/">Mentions légales</a>
              <a href="https://www.howdens-cuisines.com/conditions-generales-utilisation/">Données personnelles</a>
              <a href="https://particuliers.howdens-cuisines.com/conditions-generales-de-vente-france/">CGVS France</a>
              <a href="https://particuliers.howdens-cuisines.com/conditions-generales-de-vente-belgique/">
                CGVS Belgique
              </a>
            </nav>
            <nav>
              <p>À propos</p>
              <a href="https://www.howdens-cuisines.com/a-propos/">A propos</a>
              <a href="https://www.howdens-cuisines.com/nos-depots/">Nos dépôts</a>
            </nav>
            <nav>
              <p>Ressources</p>
              <a href="https://www.howdens-cuisines.com/catalogue/">Catalogue produits</a>
            </nav>
          </div>
        </footer>
      </BrowserRouter>

      <CookieConsent
        location="bottom"
        buttonText="Ok"
        cookieName="howdens-consents"
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        <>
          Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site. Vous pouvez en apprendre
          davantage sur cette utilisation en{' '}
          <a href="https://www.howdens-cuisines.com/conditions-generales-utilisation/" target="_blank">
            cliquant ici
          </a>
          .
        </>
      </CookieConsent>
    </div>
  )
}

export default App
