/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Field, Form, FormikErrors, FormikProps } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import Input from '../../Fields/Input'
import client from '../../../services/feathers'
// @ts-ignore
import Rodal from 'rodal'
import { useLocation } from 'react-router-dom'
import CaptchaProcess from 'src/components/Captcha/CaptchaProcess'
import useBusinessDataAutoFill from './useBusinessDataAutoFill'


export interface ISubscribeFields {
  sepaWanted?: boolean
  country?: string
  depositId?: number
  email?: string
  mobile?: string
  siren?: string
  nearZipCode?: string
  iban?: string
  bic?: string
  optinCgv?: boolean
  optinMarketSms?: boolean
  optinMarketEmail?: boolean
  captchaValidation?: boolean
  prefixMobile?: string
  from?: string
  tva?: string
  comment?: string
  addressNumber?: string
  billByEmail?: boolean
}

interface ISubscribeForm extends FormikProps<ISubscribeFields> {
  errors: FormikErrors<ISubscribeFields>
  errorsServer: Array<{
    message?: string
  }>
  otherErrors?: any
  operators: any
  depositCode: string | null
  value?: any
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}
const SubscribeForm: React.FC<ISubscribeForm> = ({
  values,
  errors,
  touched,
  setFieldValue,
  submitCount,
  errorsServer,
  otherErrors,
  operators,
  setFieldError,
  depositCode,
}) => {

  const depositsService = client.service('deposits')

  const [deposits, setDeposits] = useState([])
  const [captcha, setCaptcha] = useState(true)
  const [visible, setVisible] = useState(false)

  const [errorMessage, setErrorMessage] = useState('Vous êtes déjà client')
  const query = useQuery()

  useEffect(() => {
    if (errors && errors.siren && errors.siren.includes('Vous êtes')) {
      show(errors.siren)
    }
    if (errors && errors.tva && errors.tva.includes('Vous êtes')) {
      show(errors.tva)
    }
  }, [errors])

  useEffect(() => {
    if (otherErrors && otherErrors.data && otherErrors.data.captchaValidation) {
      setCaptcha(false)
      setTimeout(function () {
        setCaptcha(true)
      }, 1000)
    }
  }, [otherErrors])

  const show = (message: any) => {
    setVisible(true)
    setErrorMessage(message)
  }
  const hide = () => setVisible(false)

  const searchDeposit = async (e: any) => {
    setFieldValue('nearZipCode', e.target.value)
    if (e.target.value.length < 4) return

    const country = values.country ? values.country : 'FR'

    const res = await depositsService.find({
      query: { nearZipCode: `${e.target.value},${country == 'FR' ? 'France' : 'Belgium'}` },
    })

    setDeposits(res.data)
  }

  const selDeposit = (deposit: any) => {
    setFieldValue('nearZipCode', deposit.name)
    setFieldValue('depositId', deposit.id)
    setDeposits([])
  }

  const onCaptchaSuccess = () => {
    setFieldValue('captchaValidation', true)
  }

  const setCaptchaError = (message: string) => {
    setFieldError('captchaValidation', message)
  }

  const { pending: businessDataAutoFillPending } = useBusinessDataAutoFill(values, setFieldValue, Boolean(depositCode))


  return (
    <Form id="inscription">
      <Rodal visible={businessDataAutoFillPending} onClose={() => { }} showCloseButton={false}>
        <div style={{ padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div>Chargement du préremplissage des données...</div>
        </div>
      </Rodal>

      <Rodal visible={visible} onClose={hide}>
        <div>{errorMessage}</div>
      </Rodal>

      <h2>Votre pays*</h2>
      <Input as="select" name="country" full>
        <option value="">Choisir un pays</option>
        <option value="FR">France</option>
        <option value="BE">Belgique</option>
      </Input>
      <h2>Votre dépôt*</h2>
      <div style={{ width: '100%' }}>
        <p>
          Vous pouvez trouver le dépot le plus proche en{' '}
          <a href="https://www.howdens-cuisines.com/nos-depots/" rel="noopener" target="_blank">
            cliquant ici
          </a>
        </p>
        <div className="field full">
          <Field
            name="nearZipCode"
            placeholder="Renseignez votre code postal"
            className={`${errors.nearZipCode && touched.nearZipCode ? 'error' : ''}`}
            autoComplete="off"
            onChange={searchDeposit}
          />
          {deposits.length > 0 && (
            <div id="warehouses" style={{ display: 'block' }}>
              {deposits.map((deposit: any, index: number) => (
                <p key={index} onClick={() => selDeposit(deposit)}>
                  <span>{deposit.name}</span>
                </p>
              ))}
            </div>
          )}
          {errors.nearZipCode && touched.nearZipCode && <div style={{ color: '#d22630' }}>{errors.nearZipCode}</div>}
        </div>

        {operators.length > 0 && (
          <Input as="select" name="operator" full required={false}>
            <option value="">Veuillez choisir l'opérateur</option>
            {operators.map((o: any, index: number) => (
              <option key={index} value={o.email}>
                {o.firstName} {o.lastName}
              </option>
            ))}
          </Input>
        )}
      </div>
      <div className="field full">&nbsp;</div>
      <h2>1. Informations</h2>
      {values && values.country === 'FR' && <Input label="Numéro de SIREN" required name="siren" />}
      {values && values.country === 'BE' && <Input label="Numéro de TVA" required name="tva" />}
      <Input label="Nom de la société ou nom commercial" required name="name" />
      <Input label="Activité de l'entreprise" name="businessDescription" />
      {values && values.country === 'FR' && <Input label="Code APE" name="ape" />}
      {values && values.country === 'BE' && <Input label="Code Activité" name="naf" />}
      <Input label="Prénom du responsable à contacter" name="firstname" required />
      <Input label="Nom du responsable à contacter" name="lastname" required />
      <Input label="Qualité" name="job" required />
      <div className="field full">&nbsp;</div>
      <h2>2. Coordonnées du siège social</h2>

      <Input label="Numéro de voie" name="addressNumber" />
      <Input label="Voie" name="address" required />
      <Input label="Complément d'adresse" name="addressCplt" />

      <Input label="Code postal" name="zipCode" required />
      <Input label="Ville" name="city" required />
      <div className="field full">&nbsp;</div>
      <h2>3. Point de contact</h2>
      <Input label="Numéro de fixe" name="phone" />
      <div className="field">
        <label>Numéro de portable*</label>
        <div className="double">
          <Field as="select" id="indicator" name="prefixMobile">
            <option value="+33">+33</option>
            <option value="+32">+32</option>
          </Field>
          <Field name="mobile" className={`${errors.mobile && touched.mobile ? 'error' : ''}`} />
        </div>
        {errors.mobile && touched.mobile && <div style={{ color: '#d22630' }}>{errors.mobile}</div>}
      </div>
      <Input label="Numéro de fax" name="fax" />
      <Input label="E-mail" name="email" required />
      <Input label="Téléphone du service comptable si différent" name="phoneAccounting" />
      <Input label="E-mail du service comptable si différent" name="emailAccounting" />
      <Input as="select" name="from" full>
        <option value="">Comment nous avez-vous connu ?</option>
        <option value="Email">Email</option>
        <option value="Téléphone">Téléphone</option>
        <option value="Courrier">Courrier</option>
        <option value="Bouche à oreille">Bouche à oreille</option>
        <option value="Radio">Radio</option>
        <option value="Article de presse">Article de presse</option>
        <option value="Internet & site web">Internet & site web</option>
        <option value="Salon BATIMAT">Salon BATIMAT</option>
        <option value="Autre">Autre</option>
      </Input>
      {depositCode !== null &&
        <Input label="Commentaire" name="comment" as="textarea" full rows={4} maxLength={501} resizeNone />
      }
      {values && values.country === 'FR' && (
        <>
          <div className="field full">&nbsp;</div>
          <h2>4. Mandat SSP</h2>
          <div className="field full chk">
            <label htmlFor="iban">
              Avez-vous pensé au prélèvement SSP ?<br /> Sécurisé, facile, révocable à tout moment, le meilleur moyen de ne
              pas oublier de payer une facture.
            </label>
          </div>
          <Input full label="Je souhaite adhérer au prélèvement SSP" type="checkbox" name="sepaWanted" />
          {values.sepaWanted && (
            <div id="sepa_fields" className="visible">
              <Input label="IBAN" name="iban" required />
              <Input label="BIC" name="bic" required />
              <Input full type="checkbox" name="sepaAccepted" required >
                En signant ce formulaire de mandat, vous autorisez la société HOUDAN CUISINES S.A.S. à envoyer des
                instructions à votre banque pour débiter votre compte, et votre banque à débiter votre compte conformément
                aux instructions de HOUDAN CUISINES S.A.S
                <br />
                <br />
                <small>
                  {' '}
                  Vous bénéficiez du droit d'être remboursé par votre banque selon les conditions décrites dans la
                  convention que vous avez passée avec elle.
                  <br /> Une demande de remboursement doit être présentée :<br /> &nbsp;&nbsp;&nbsp;&nbsp;- dans les 8
                  semaines suivant la date de débit de votre compte pour un prélèvement autorisé,
                  <br /> &nbsp;&nbsp;&nbsp;&nbsp;- sans tarder et au plus tard dans les 13 mois en cas de prélèvement non
                  autorisé.
                  <br /> Vos droits concernant le présent mandat sont expliqués dans un document que vous pouvez obtenir
                  auprès de votre banque.{' '}
                </small>
              </Input>
            </div>
          )}
        </>)}
      {values && values.country === 'BE' && (
        <>
          <div className="field full">&nbsp;</div>
          <h2>4. Mandat SEPA</h2>
          <div className="field full chk">
            <label htmlFor="iban">
              Avez-vous pensé au prélèvement SEPA ?<br /> Sécurisé, facile, révocable à tout moment, le meilleur moyen de ne
              pas oublier de payer une facture.
            </label>
          </div>
          <Input full label="Je souhaite adhérer au prélèvement SEPA" type="checkbox" name="sepaWanted" />
          {values.sepaWanted && (
            <div id="sepa_fields" className="visible">
              <Input label="IBAN" name="iban" required />
              <Input label="BIC" name="bic" required />
              <Input full type="checkbox" name="sepaAccepted" required >
                En signant ce formulaire de mandat, vous autorisez la société HOUDAN CUISINES S.A.S. à envoyer des
                instructions à votre banque pour débiter votre compte, et votre banque à débiter votre compte conformément
                aux instructions de HOUDAN CUISINES S.A.S
                <br />
                <br />
                <small>
                  {' '}
                  Vous bénéficiez du droit d'être remboursé par votre banque selon les conditions décrites dans la
                  convention que vous avez passée avec elle.
                  <br /> Une demande de remboursement doit être présentée :<br /> &nbsp;&nbsp;&nbsp;&nbsp;- dans les 8
                  semaines suivant la date de débit de votre compte pour un prélèvement autorisé,
                  <br /> &nbsp;&nbsp;&nbsp;&nbsp;- sans tarder et au plus tard dans les 13 mois en cas de prélèvement non
                  autorisé.
                  <br /> Vos droits concernant le présent mandat sont expliqués dans un document que vous pouvez obtenir
                  auprès de votre banque.{' '}
                </small>
              </Input>
            </div>
          )}
        </>)}
      <div className="field full">&nbsp;</div>
      <h2>5. Confirmation de votre inscription</h2>

      <div className="field full">
        {captcha && values.captchaValidation !== true && (
          <CaptchaProcess onSuccess={onCaptchaSuccess} setError={setCaptchaError} />
        )}
      </div>
      {errors.captchaValidation && <div style={{ color: '#d22630', marginBottom: 20 }}>{errors.captchaValidation}</div>}

      <Input
        full
        label="Souhaitez-vous recevoir vos factures par courrier électronique ??"
        type="checkbox"
        name="billByEmail"
      />
      <Input
        full
        label="J'accepte que les informations ci-dessus soient utilisées pour me contacter et m'envoyer de l'information et
          des promotions par e-mail"
        type="checkbox"
        name="optinMarketEmail"
      />
      <Input
        full
        label="J'accepte que les informations ci-dessus soient utilisées pour me contacter et m'envoyer de l'information et
          des promotions par SMS"
        type="checkbox"
        name="optinMarketSms"
      />

      {query.get('depot') && (
        <Input type="checkbox" name="contractAlreadySigned" full>
          Les contrats sont-ils déjà signés ?
        </Input>
      )}

      <Input type="checkbox" name="optinCgv" full required>
        J'accepte les{' '}
        {(!values.country || values.country == 'FR') && (
          <a href="https://particuliers.howdens-cuisines.com/conditions-generales-de-vente-france/" target="_blank">
            conditions générales de vente
          </a>
        )}
        {values.country == 'BE' && (
          <a href="https://particuliers.howdens-cuisines.com/conditions-generales-de-vente-belgique/" target="_blank">
            conditions générales de vente
          </a>
        )}{' '}
        d'Howdens Cuisines
      </Input>
      {errors && Object.keys(errors) && Object.keys(errors).length !== 0 && submitCount > 0 && (
        <div className="field full errors" style={{ display: 'block' }}>
          {Object.values(errors).map((val, index: number) => (
            <p key={index}>{val}</p>
          ))}
        </div>
      )}
      {errorsServer.length !== 0 && Array.isArray(errorsServer) && submitCount > 0 && (
        <div className="field full errors" style={{ display: 'block' }}>
          {errorsServer.map((err, index: number) => (
            <p key={index}>{err.message}</p>
          ))}
        </div>
      )}
      {otherErrors && otherErrors.message && (
        <div className="field full errors" style={{ display: 'block' }}>
          {otherErrors.message}
        </div>
      )}

      <div className="field full">
        <p className="warning">
          <span>
            Afin de finaliser votre ouverture de compte, vous allez devoir signer électroniquement nos conditions
            générales de ventes ainsi que le mandat si vous avez choisi cette option.
          </span>{' '}
          Votre signature est nécessaire pour valider votre ouverture de compte chez HOWDENS CUISINES et s'effectue via
          la plateforme Yousign. Cette action est totalement gratuite et sécurisée.
          <br />
          <span>Un email vous sera envoyé à l'adresse que vous nous avez fourni afin de signer ces documents</span>.
        </p>
      </div>
      <div>
        <button className="cta" type="submit">
          Validez et continuez
        </button>
      </div>
    </Form>
  )
}

export default SubscribeForm
