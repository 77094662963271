export interface IBusinessDataResponse {
  code: number
  data?: {
    name: string
    activityCode: string
    activity: string
    manager: {
      firstname: string | null
      lastname: string | null
      name: string | null
      position: string
    }
    address: string
    addressNumber: string
    city: string
    zipCode: string
    country: string
  }
}

export const fetchBusinessData = async (businessCode: string): Promise<IBusinessDataResponse> => {
  let result = await fetch(`${process.env.REACT_APP_API_URL}/get-business-data/${businessCode}`)
  return result.json()
}
