const routes = {
  HOME: '/',
  FORM: '/subscribe',
  UPLOAD: '/uploads/:subscriptionId/:subscriptionToken',
  NOT_AUTORIZED: '/not-authorized',
  THANKS: '/thanks',
  MAINTENANCE: '/maintenance'
}

export default routes
