import { object, string, boolean, number } from 'yup'
import { ISubscribeFields } from './index'
import request from 'superagent'

const nameValidation = /^[a-zàâçéèêëîïôûùüÿñæœ -]*$/i
const addressNumber = /([0-9]+[a-zA-Z]{0,16})/


export const validateSubscribeForm = object().shape({
  country: string().required('Le champ "Pays" est obligatoire.'),
  nearZipCode: string().required('Le champ "Dépôt" est obligatoire.'),
  name: string().required('Le champ "nom de la société" est obligatoire.'),
  // businessDescription: string().required('Le champ "Activité de l’entreprise" est obligatoire.'),
  firstname: string()
    .matches(nameValidation, 'Le champs "Prénom" contient des caractères invalides')
    .required('Le champ "Prénom du responsable" est obligatoire.'),
  lastname: string()
    .matches(nameValidation, 'Le champs "Nom" contient des caractères invalides')
    .required('Le champ "Nom du responsable" est obligatoire.'),
  job: string().required('Le champ "Qualité du responsable" est obligatoire.'),
  address: string().required('Le champ "Adresse" est obligatoire.'),
  addressNumber: string().matches(addressNumber, 'Numéro non valide'),
  zipCode: string().required('Le champ "Code postal" est obligatoire.'),
  city: string().required('Le champ "Ville" est obligatoire.'),
  phone: string().matches(/^[0-9() .+-]+$/i, 'Format téléphone incorrect'),
  fax: string().matches(/^[0-9() .+-]+$/i, 'Format fax incorrect'),
  phoneAccounting: string().matches(/^[0-9() .+-]+$/i, 'Format téléphone incorrect'),
  emailAccounting: string().email('Ce n’est pas un email valide'),
  captchaValidation: string().required('Vous devez sélectionner la bonne image'),
  mobile: string()
    .matches(/^[123456789]{1}(.*)/i, 'Le mobile ne peut commencer par un 0')
    .matches(/^[1-9][0-9]{8}$/i, 'Format mobile incorrect')
    .required('Le champ "Numéro de téléphone portable" est obligatoire.'),
  email: string().email('Ce n’est pas un email valide').required('Le champ "Email" est obligatoire.'),
  sepaWanted: boolean(),
  comment: string().max(500, 'le commentaire ne doit pas dépasser 500 caractères'),/* commentaire mis dans le model subscription ne depassant pas 500 caractères */
  tva: string().when('country', {
    is: (val: string) => val === 'BE',
    then: string()
      .matches(/^BE[0-9]{10}$/i, 'Format TVA incorrect')
      .required('Le champ "TVA" est obligatoire. ')
      .test('unique', async function (value, testContext) {
        const { createError } = this

        if (value === undefined) return true
        const res = await request.get(`${process.env.REACT_APP_API_URL}/customers`).query({
          tvaBelgium: value,
        })
        console.log(res.body.data)
        // @ts-ignore
        return res.body.data.length > 0
          ? createError({
            message: `Vous êtes déjà client (ref n°${res.body.data[0].ref}) chez ${res.body.data[0].depotName}`,
            path: 'tva', // Fieldname
          })
          : true
      }),
  }),
  // naf: string().when('country', {
  //   is: (val: string) => val === 'BE',
  //   then: string().required('Le champ "Code Activité" est obligatoire. '),
  // }),
  siren: string().when('country', {
    is: (val: string) => val === 'FR',
    then: string()
      .matches(/^[0-9]{9}$/i, 'Format SIREN incorrect')
      .required('Le champ "SIREN" est obligatoire. ')
      .test('validator-custom-name', async function (value, testContext) {
        const { createError } = this
        if (value === undefined) return true
        const res = await request.get(`${process.env.REACT_APP_API_URL}/customers`).query({
          siren: value,
        })

        // @ts-ignore
        return res.body.data.length > 0
          ? createError({
            message: `Vous êtes déjà client (ref n°${res.body.data[0].ref}) chez ${res.body.data[0].depotName}`,
            path: 'siren', // Fieldname
          })
          : true
      }),
  }),
  // ape: string().when('country', {
  //   is: (val: string) => val === 'FR',
  //   then: string()
  //     .matches(/^[0-9]{4}[A-Z]{1}$/i, 'Format APE incorrect')
  //     .required('Le champ "Code APE" est obligatoire. '),
  // }),
  iban: string().when('sepaWanted', {
    is: true,
    then: string()
      .matches(/^[0-9A-Z]{27}$/i, 'Format IBAN incorrect')
      .required('Le champ "IBAN" est obligatoire. '),
  }),
  bic: string().when('sepaWanted', {
    is: true,
    then: string()
      .matches(/^([0-9A-Z]{8}|[0-9A-Z]{11})$/i, 'Format BIC incorrect')
      .required('Le champ "BIC" est obligatoire. '),
  }),

  sepaAccepted: boolean().when('sepaWanted', {
    is: true,
    then: boolean()
      .required('Veuillez confirmer la souscription au mandat.'),
  }),
  optinCgv: boolean().oneOf([true], 'Les Conditions générales de vente doivent être acceptées. '),
})

export const validate = (values: ISubscribeFields) => {
  const errors: ISubscribeFields = {}

  return errors
}
