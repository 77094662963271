import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Loader from '../Loader'
import request from 'superagent'
import client from '../../services/feathers'
import { fileToBase64 } from '../../utils/file'
// @ts-ignore
import Rodal from 'rodal'
// include styles
import 'rodal/lib/rodal.css'

export interface DocumentI {
  id?: number
  name?: string
  status: number
  statusName?: string
  document_type: {
    comment: string
    id?: string
    mustBeSigned?: boolean
    mustBeUploaded?: boolean
  }
  datas?: {
    youSign?: {
      urlProcecure?: string
    }
    ssp?: {
      signatureUrl?: string
    }
  }
}

export interface DocumentPropsI {
  subscriptionToken: string
  subscriptionId: string
  data: DocumentI
}

const DocumentCompo: React.FC<DocumentPropsI> = ({ subscriptionToken, subscriptionId, data }) => {
  const [isPending, setIsPending] = useState(false)
  const [isUploded, setIsUploaded] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [error, setError] = useState({})
  const document = data
  const [isVisible, setIsVisible] = useState(false)
  const [signUrl, setSignUrl] = useState<string>()

  /**
   * UPLOAD
   */
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
    maxFiles: 2,
    onDrop: async (files) => {
      setIsPending(true)
      setError({})
      setHasError(false)
      setIsUploaded(true)

      const base54 = await fileToBase64(files[0])

      try {
        const res = await request
          .put(`${process.env.REACT_APP_API_URL}/upload/${document.id}`)
          .send({
            file: base54,
            name: files[0].name,
          })
          .set('subscription_token', subscriptionToken)
          .set('subscription_id', subscriptionId)
      } catch (error: any) {
        setError(error)
        console.log(error)
        setHasError(true)
      } finally {
        setIsPending(false)
      }
    },
  })

  // HF-206
  const goToSign = async (document: DocumentI) => {
    if (document.status == 100 && document.datas) {
      if (document.document_type.id === 'SEPA' && document.datas.ssp && document.datas.ssp.signatureUrl) {
        window.location.href = document.datas.ssp.signatureUrl

        console.log({ test: document.datas.ssp.signatureUrl })
      } else if (document.datas.youSign) {
        setSignUrl(document.datas.youSign.urlProcecure)

        toggle()
      }
    }

    return false
  }

  const toggle = () => {
    setIsVisible(!isVisible)
  }

  return (
    <>
      {document.document_type.mustBeUploaded && (
        <div
          style={{
            border: '1px solid #d22630',
            textAlign: 'center',
            position: 'relative',
            cursor: 'pointer',
            width: 300,
            height: 190,
            padding: 10,
            margin: 10,
          }}
          {...getRootProps({ className: 'dropzone' })}
        >
          {isPending && <Loader />}
          <div style={{ fontSize: 18, marginBottom: 20 }}>{document.name}</div>
          {document.document_type.comment && <p>{document.document_type.comment}</p>}
          <div style={{ marginBottom: 20 }}>{document.statusName}</div>

          {(hasError || !isUploded) && <input {...getInputProps()} />}

          {isUploded && !hasError && !isPending && (
            <p style={{ color: 'rgb(38, 210, 60)' }}>Merci, votre document est déposé avec succès</p>
          )}
          {isUploded && hasError && !isPending && (
            <p style={{ color: '#d22630' }}>
              Une erreur est survenue, merci de déposer un document au format jpg, png ou pdf de moins de 10Mo
            </p>
          )}
          {isPending && <p>Envoie en cours ...</p>}
          {!isUploded && !isPending && <p>Cliquez pour déposer votre document</p>}
        </div>
      )}
      {document.document_type.mustBeSigned && (
        <div
          style={{
            border: '1px solid #d22630',
            textAlign: 'center',
            position: 'relative',
            width: 300,
            height: 190,
            cursor: [100].includes(document.status) ? 'pointer' : 'default',
            padding: 10,
            margin: 10,
          }}
          onClick={() => goToSign(document)}
        >
          <Rodal
            visible={isVisible}
            onClose={toggle}
            customStyles={{
              width: 'auto',
              height: 'auto',
              minWidth: 'auto',
              maxWidth: 'auto',
            }}
          >
            <iframe
              style={{
                width: '90%',
                height: '90%',
                minWidth: 'auto',
                maxWidth: 'auto',
              }}
              src={signUrl}
            ></iframe>
          </Rodal>
          <div style={{ fontSize: 18, marginBottom: 20 }}>{document.name}</div>
          <div style={{ marginBottom: 20 }}>{document.statusName}</div>

          {[210, 240].includes(document.status) && (
            <p style={{ color: 'rgb(38, 210, 60)' }}>Le document a été signé, merci. </p>
          )}
          {[100].includes(document.status) && <p>Cliquez ici pour signer votre contrat</p>}
        </div>
      )}
    </>
  )
}

export default DocumentCompo
