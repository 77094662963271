import React, { useEffect, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { useHistory } from 'react-router-dom'
import { validate, validateSubscribeForm } from '../../components/Forms/SubscribeForm/validate'
import FormSubscribe, { ISubscribeFields } from '../../components/Forms/SubscribeForm'
import client from '../../services/feathers'
import Loader from '../../components/Loader'
import useFeathersSocketClient from 'src/hooks/useFeathersSocketClient'

const Home: React.FC = () => {
  const subscribeService = client.service('subscriptions')

  const [isPending, setIsPending] = useState(false)
  const [errors, setErrors] = useState<any[]>([])
  const [serverError, setServerError] = useState({})
  const [initialValues, setInitialValues] = useState({
    optinCgv: false,
    prefixMobile: '+33',
    optinMarketEmail: true,
    optinMarketSms: true,
    depositId: undefined,
    nearZipCode: undefined,
    comment: '',
    addressNumber: '',
    billByEmail: false,
  })
  const [isLoading, setIsLoading] = useState(true)
  const [operators, setOperators] = useState([])

  const history = useHistory()

  const onSubmit = async (values: ISubscribeFields, test: boolean = false) => {
    setIsPending(true)

    const toSend = JSON.parse(JSON.stringify(values))
    toSend.mobile = `${toSend.prefixMobile}${toSend.mobile}`
    setServerError({})
    setErrors([])
    try {
      const res = await subscribeService.create({
        ...toSend,
        addressNumber: values.addressNumber && values.addressNumber !== '' ? values.addressNumber : '0',
        isPublic: !Boolean(depot)
      })
      console.log({ address: values.addressNumber })
      history.push(`/uploads/${res.id}/${res.secutityToken}`)
    } catch (error: any) {
      console.log({ error })
      if (!Array.isArray(error.errors)) {
        setServerError(error)
      }
      if (error.errors) setErrors(error.errors)
      else {
        console.log(error)
        setErrors([{ internal: 'Erreur serveur' }])
      }
    } finally {
      setIsPending(false)
    }
  }

  const queryParams = new URLSearchParams(window.location.search)
  const depot = queryParams.get('depot')



  useEffect(() => {
    try {
      getDeposit()
    } catch (error) {
      console.log({ error })
    }
  }, [depot])

  const getDeposit = async () => {
    if (depot) {
      const depositsService = client.service('deposits')
      const res = await depositsService.find({
        query: { ref: depot },
      })

      const newInitialValues = { ...initialValues }
      newInitialValues.depositId = res.data[0].id
      newInitialValues.nearZipCode = res.data[0].name
      setInitialValues(newInitialValues)

      const operatorsServices = client.service('operators')
      const resOperators = await operatorsServices
        .find({
          query: {
            $limit: 10000,
            depotCode: depot,
          },
        })
        .catch((e: any) => console.log(e))

      console.log(resOperators)

      setOperators(resOperators.data)
    }
    setIsLoading(false)
  }

  return (
    <div className="wrap" style={{ position: 'relative' }}>
      {isPending && <Loader />}

      {!isLoading && (
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmit(values, false)}
          validate={validate}
          validationSchema={validateSubscribeForm}
        >
          {(formikProps: FormikProps<ISubscribeFields>) => (
            <FormSubscribe
              depositCode={depot}
              {...formikProps}
              errorsServer={errors}
              otherErrors={serverError}
              operators={operators}
            />
          )}
        </Formik>
      )}
    </div>
  )
}

export default Home
