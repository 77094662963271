import React, { useState, useEffect } from 'react'

const Thanks: React.FC = () => {
  return (
    <div className="wrap" style={{ textAlign: 'center', margin: '150px auto', fontSize: 18 }}>
      <h1>Confirmation d'inscription</h1>
      <p>Merci pour votre confiance.</p>
      <p>Votre inscription a bien été prise en compte et est en cours de gestion par nos équipes.</p>
      <p>
        Afin de finaliser votre dossier, nous vous invitons à consulter vos e-mails à l’adresse que vous nous avez
        fourni afin de signer les conditions générales de ventes ainsi que le mandat SEPA si vous avez choisi cette
        option.
      </p>
      <p>Cette action est nécessaire pour valider votre ouverture de compte chez Howdens Cuisines.</p>
      <p>
        Si vous n’avez pas reçu d’email, merci de consulter vos courriers indésirables ou de contacter votre dépôt
        Howdens Cuisines.
      </p>
      <p>À très bientôt dans nos dépôts !</p>
    </div>
  )
}

export default Thanks
